<template>
  <div class="main-title">
    <h2>{{ $t("PRODUCT.ADD_PRODUCT") }}</h2>
  </div>
  <div class="bg-sections">
    <ProductForm />
  </div>
</template>

<script>
import ProductForm from "@/components/ProductForm.vue"

export default {
  name: "AddProduct",
  components: {
    ProductForm,
  },
};
</script>
