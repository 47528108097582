import axios from "axios"
import { defineStore } from "pinia"

export const useCountryStore = defineStore("country", {
  // id: "sales",
  state: () => ({
    _status: null,
    _error: null,
    _countries: null,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isLoadingAll: (state) => state._status === "loadingAll",
    isReadyAll: (state) => state._status === "readyAll",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    countries: (state) => state._countries,
  },
  actions: {
    async getCountry() {
      this.changeStatus("loading");

      try {
        const response = await axios.get(`https://restcountries.com/v3.1/all`);
        if (response.status === 200)
          this._countries = response.data.map((country) => {
            return {
              short: country.cca2,
              name: country.name.common,
            };
          });
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
