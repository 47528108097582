// src/store/index.js
import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
  state: {
    products: [],
    categories: []
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    addProduct(state, product) {
      state.products.push(product);
    }
  },
  actions: {
    async fetchCategories({ commit }) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/categories/`);
        commit('setCategories', response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    async createProduct({ commit }, productData) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/products/`, productData);
        commit('addProduct', response.data);
      } catch (error) {
        console.error('Error creating product:', error);
      }
    }
  },
  getters: {
    allProducts: state => state.products,
    allCategories: state => state.categories
  }
})
