<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 13.5H10.5V15H6V13.5ZM6 16.5H13.5V18H6V16.5Z" fill="white" />
    <path
      d="M19.5 3H4.5C4.10236 3.0006 3.72117 3.15882 3.44 3.44C3.15882 3.72117 3.0006 4.10236 3 4.5V19.5C3.0006 19.8976 3.15882 20.2788 3.44 20.56C3.72117 20.8412 4.10236 20.9994 4.5 21H19.5C19.8976 20.9994 20.2788 20.8412 20.56 20.56C20.8412 20.2788 20.9994 19.8976 21 19.5V4.5C20.9994 4.10236 20.8412 3.72117 20.56 3.44C20.2788 3.15882 19.8976 3.0006 19.5 3ZM13.5 4.5V7.5H10.5V4.5H13.5ZM4.5 19.5V4.5H9V9H15V4.5H19.5L19.5007 19.5H4.5Z"
      fill="white"
    />
  </svg>
</template>
