<script setup>
import Spinner from "@/assets/icons/SpinnerLoad.vue"
import { defineProps } from "vue"

const props = defineProps({
  headers: {
    type: Object,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  noHaveData: {
    type: Boolean,
    default: false,
  },
  pagination: {
    type: Object,
    default: () => {},
  },
  havePagination: {
    type: Boolean,
    default: true,
  },
  heightTable: {
    type: String,
    default: "",
  },
  title: {
    type: String,
  },
});
</script>
<template>
  <table class="table">
    <thead>
      <tr>
        <th
          v-for="(head, index) in props.headers"
          :key="index"
          scope="col"
          class="p-3"
          :class="head.width"
          v-text="head.title"
        />
      </tr>
    </thead>
    <tbody>
      <template v-if="props.isLoading">
        <tr>
          <td
            class="bg-white py-3 text-center text-sm m-auto"
            :colspan="props.headers?.length"
          >
            <Spinner />
          </td>
        </tr>
      </template>

      <template v-else-if="props.noHaveData">
        <tr>
          <td
            class="bg-white py-3 text-center text-sm"
            :colspan="props.headers?.length"
            v-text="'No hay datos disponible'"
          />
        </tr>
      </template>

      <template v-else>
        <slot name="body" />
      </template>
    </tbody>
  </table>
</template>
