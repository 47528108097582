<template>
    <div class="notFound">
        <p>404</p>
        <h2>
            Error: Vuelva a intentarlo en otro momento o contáctese con soporte.
        </h2>
    </div>
</template>

<style>
.notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.notFound p{
    font-size: 52px
}
</style>