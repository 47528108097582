import axios from "axios"
import { defineStore } from "pinia"

export const usePayoutStore = defineStore("Payouts", {
  // id: "sales",
  state: () => ({
    _status: null,
    _error: null,
    _data: null,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isLoadingAll: (state) => state._status === "loadingAll",
    isReadyAll: (state) => state._status === "readyAll",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    data: (state) => state._data,
  },
  actions: {
    async getPayouts() {
      this.changeStatus("loading");

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/payouts/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this._data = response.data.results;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
