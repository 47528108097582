<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 4C16.8978 4 17.2794 4.15804 17.5607 4.43934C17.842 4.72064 18 5.10218 18 5.5C18 5.89782 17.842 6.27936 17.5607 6.56066C17.2794 6.84196 16.8978 7 16.5 7C16.1022 7 15.7206 6.84196 15.4393 6.56066C15.158 6.27936 15 5.89782 15 5.5C15 5.10218 15.158 4.72064 15.4393 4.43934C15.7206 4.15804 16.1022 4 16.5 4Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.77401 10.1445C0.771009 11.2645 0.750009 12.9545 1.67001 14.1445C3.45702 16.4643 5.53623 18.5435 7.85601 20.3305C9.04601 21.2505 10.736 21.2295 11.856 20.2265C14.8836 17.5183 17.7285 14.6126 20.372 11.5285C20.6389 11.2217 20.8032 10.8392 20.842 10.4345C21.006 8.63847 21.345 3.46447 19.94 2.06047C18.535 0.656468 13.362 0.994468 11.566 1.15947C11.1613 1.19825 10.7788 1.36258 10.472 1.62947C7.38788 4.27268 4.48219 7.11724 1.77401 10.1445Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.788 11.3673C12.81 10.9653 12.922 10.2323 12.312 9.67433M12.312 9.67433C12.0792 9.47209 11.8082 9.31872 11.515 9.22333C10.258 8.78033 8.71496 10.2623 9.80696 11.6193C10.394 12.3493 10.847 12.5733 10.803 13.4013C10.773 13.9833 10.201 14.5923 9.44696 14.8243C8.79196 15.0263 8.06896 14.7593 7.61196 14.2483C7.05296 13.6243 7.10996 13.0363 7.10496 12.7803M12.312 9.67433L13 8.98633M7.65996 14.3263L7.00696 14.9793"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
