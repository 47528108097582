<script setup>
import { defineEmits, defineProps } from "vue"

const props = defineProps({
  id: {
    type: String,
  },
  modelValue: {
    type: [String, Number],
  },
  placeholder: {
    type: String,
    default: "",
  },
  name: {
    type: String,
  },
  type: {
    default: "text",
  },
  isDisabled: {
    type: Boolean,
  },
  errorMessage: {
    type: String,
  },
  label: {
    type: String,
  },
  isRequerid: {
    type: Boolean,
  },
  hasSpinners: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(["update:modelValue"]);
const handleInput = (_event) => emit("update:modelValue", _event.target.value);
</script>
<template>
  <label v-show="props.label" class="textField">
    <span
      :class="props.errorMessage ? 'text-red-500 ' : ''"
      class="w-full font-medium text-background-dark"
      v-text="props.label"
    />
    <span v-if="isRequerid" class="text-red-500">*</span>
    <input
      :id="props.id"
      class="form-control"
      :class="[
        props.errorMessage ? 'form-error-input ' : '',
        isDisabled
          ? 'cursor-not-allowed  bg-gray-700 bg-opacity-10'
          : 'bg-transparent ',
          !props.hasSpinners ? 'no-spinners' : ''
      ]"
      :name="props.name"
      :type="type"
      :placeholder="props.placeholder"
      :value="props.modelValue"
      :disabled="props.isDisabled"
      autocomplete="off"
      :maxlength="props.maxlength"
      @input="handleInput"
    />

    <p v-if="props.errorMessage" v-text="props.errorMessage" />
  </label>
</template>
<style scoped>
.textField {
  position: relative;
  width: 100%;
}
.textField p {
  color: red;
  position: absolute;
  font-size: 12px;
  margin-top: 1px;
}
.textField span{
  font-weight: 500;
}
.form-error-input{
  border: 1px solid red;
}
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}
</style>
