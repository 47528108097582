<script setup>
import DataTable from "@/components/common/DataTable.vue"
import { usePayoutStore } from "@/stores/Payouts"
import { useSalesStore } from "@/stores/Sales"
import { computed, onMounted } from "vue"

const salesStore = useSalesStore();
const payoutStore = usePayoutStore();

const titlesTable = computed(() => [
  {
    width: "",
    title: "Monto",
  },
  {
    width: "w-4/12",
    title: "Fecha",
  },


]);

onMounted(async () => {
  await salesStore.getSales();
  await payoutStore.getPayouts();
})

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}
</script>
<template>
  <section>
    <div class="main-title">
      <h2>Pagos</h2>
<!-- 
      <div class="d-flex gap-4">
        <p v-text="'Filtrar'" class="mt-4 pt-2" />
        <div class="d-flex gap-4">
          <TextField
            id="search"
            type="text"
            name="productPrice"
            :label="'Producto'"
            placeholder="Buscar "
          />

         <div class="w-100">
          <p v-text="'Estado'" class="mb-0" />
          <select class="form-select" aria-label="Default select example">
            <option selected>Seleccionar</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
         </div>
        </div>
      </div> -->
    </div>
    <DataTable :noHaveData="payoutStore.data?.length === 0" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in payoutStore.data" :key="index">
          <td class="p-3" v-text="item.amount || '-'" />
          <td class="p-3" v-text="formatDate(item.created_at) || '-'" />
        </tr>
      </template>
    </DataTable>
  </section>
</template>
