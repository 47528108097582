import QuestionaireForm from "@/components/forms/QuestionaireForm.vue"
import AffiliateProducts from "@/views/AffiliateProducts.vue"
import AffiliateSalesList from "@/views/AffiliateSalesList.vue"
import AffiliatesList from "@/views/AffiliatesList.vue"
import allProducts from "@/views/AllProducts.vue"
import Home from "@/views/HomeMain.vue"
import NotFound from "@/views/NotFound.vue"
import OffersList from "@/views/OffersList.vue"
import PayoutsList from "@/views/PayoutsList.vue"
import AddProduct from "@/views/Product/AddProduct.vue"
import EditProduct from "@/views/Product/EditProduct.vue"
import AppProfile from "@/views/Profile.vue"
import SalesList from "@/views/SalesList.vue"
import ServerError from "@/views/ServerError.vue"
import { createAuthGuard } from "@auth0/auth0-vue"
import App from "vue"
import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: "/",
    name: "dashboard-home",
    component: Home,
    beforeEnter: createAuthGuard(App),
    children: [
      {
        path: "/",
        name: "home",
        component:
          process.env.VUE_APP_APP_TYPE === "skillper"
            ? QuestionaireForm
            : AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/products",
        name: "products",
        component: allProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/sales",
        name: "sales",
        component: SalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-sales",
        name: "affiliate-sales",
        component: AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-products",
        name: "affiliate-products",
        component: AffiliateSalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/addProduct",
        name: "AddProduct",
        component: AddProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/edit-product",
        name: "editProduct",
        component: EditProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        component: AppProfile,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliates/:id?",
        name: "affiliates",
        component: AffiliatesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/payouts",
        component: PayoutsList,
        meta: { requiresAuth: true },
      },
      {
        path: "/offers",
        component: OffersList,
        meta: { requiresAuth: true },
      },
      {
        path: "/404",
        name: "NotFound",
        component: NotFound,
        meta: { requiresAuth: true }
      },
      {
        path: "/500",
        name: "ServerError",
        component: ServerError,
        meta: { requiresAuth: true }
      },
      {
        path: '/:pathMatch(.*)',
        name: "NorFound",
        redirect: {name: "NotFound"},
        meta: { requiresAuth: true }
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/*router.beforeEach((to, from, next) => {
  const authService = getInstance();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated) {
      return authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
  }

  next();
});
*/

export default router;
