<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light w-100 bg-white ">
    <a class="navbar-brand" href="#">
      <img :src="user?.picture" alt="Profile Picture" class="profile-picture">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <!-- <li class="nav-item">
          <a class="nav-link" href="#"><i class="fas fa-gift"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"><i class="fas fa-bell"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"><i class="fas fa-search"></i></a>
        </li> -->
        <li class="nav-item dropdown" v-if="isAuthenticated">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img :src="user?.picture" alt="Profile Picture" class="profile-picture-sm"> {{ user.name }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><router-link to="/profile" class="dropdown-item" href="#">{{ $t('PROFILE.PROFILE') }}</router-link></li>
            <li><a class="dropdown-item" href="#">Opciones</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#" @click="logout">Cerrar sesión</a></li>
          </ul>
        </li>
        <li class="nav-item" v-else>
          <a class="nav-link" href="#" @click="login">Iniciar sesión</a>
        </li>
      </ul>
    </div>
    <!-- <pre>
      {{user}}

    </pre> -->
  </nav>
</template>


<script>
import { useAuth0 } from '@auth0/auth0-vue'

export default {
  name: 'AppHeader',
  setup() {
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

    return {
      login: () => {
        loginWithRedirect();
      },
      logout: () => {
        logout({ returnTo: window.location.origin });
      },
      user,
      isAuthenticated
    };
  }
};
</script>
<style scoped>
.navbar {
  padding: 10px 20px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10000;
}
.nav-link {
  color: #343a40 !important;
}
.nav-link:hover {
  color: #007bff !important;
}
.profile-picture {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.profile-picture-sm {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
</style>
