<template>
  <section>
    <div class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.AFFILIATE_PRODUCTS") }}</h2>
      </div>
      
    </div>

    <DataTable v-if="filteredProducts.length > 0" :headers="productTableHeaders">
      <template #body>
        <tr v-for="(product, index) in filteredProducts" :key="index">
          <td class="p-3" v-text="product.product.title || '-'" />
          <td class="p-3" v-text="product.percentage + '%' || '-'" />
          <td class="p-3" v-text="formatPrice(product.product.price) || '-'" />
          <td class="p-3" v-text="product.product.categories[0] || '-'" />
          <td class="p-3" v-text="product.product.producer || '-'" />
          <td class="p-3" v-text="product.status || '-'" />
          <td class="p-3">
            <button class="btn bg-green text-white" @click="copyLink(baseURL + '/products/detail/' + product.product.id + '?utm_affiliate=' + product.affiliate.utm)">
              Copiar link
            </button>
          </td>
        </tr>
      </template>
    </DataTable>
  </section>
</template>

<script setup>
import DataTable from "@/components/common/DataTable.vue"
import { useAffiliateStore } from "@/stores/Affiliate"
import { computed, onMounted, ref, watch } from "vue"
import { useAuth0 } from "@auth0/auth0-vue"

const affiliateStore = useAffiliateStore();
const { user } = useAuth0();
const baseURL = process.env.VUE_APP_MARKET_URL;
const productTableHeaders = computed(() => [
  {
    width: "w-3/12",
    title: "Nombre del producto",
  },
  {
    width: "w-4/12",
    title: "Comisión",
  },
  {
    width: "w-1/12",
    title: "Precio",
  },
  {
    width: "w-2/12",
    title: "Categoría",
  },
  {
    width: "w-2/12",
    title: "Productor",
  },
  {
    width: "w-2/12",
    title: "Estado",
  },
  {
    width: "w-2/12",
    title: "Link de Afiliado",
  },
]);

const productSearch = ref('');
const currentPage = ref(1);

onMounted(async () => {
  if (user.value) {
    await fetchAffiliateProducts();
  }
});

const affiliateProducts = computed(() => affiliateStore.affiliateProducts);

const filteredProducts = computed(() => {
  return affiliateProducts.value.filter(product =>
    product.product.title.toLowerCase().includes(productSearch.value.toLowerCase())
  );
});


function formatPrice(price) {
  return `$${parseFloat(price).toFixed(2)}`;
}

watch(user, async (newUser) => {
  if (newUser) {
    await fetchAffiliateProducts();
  }
}, { immediate: true });

async function fetchAffiliateProducts() {
  if (user.value && user.value.sub) {
    const userId = user.value.sub;
    await affiliateStore.getAffiliateProducts(userId);
  }
}

function copyLink(link) {
  navigator.clipboard.writeText(link).then(() => {
    console.log('Link copied to clipboard');
  }).catch(err => {
    console.error('Failed to copy link: ', err);
  });
}

watch(currentPage, fetchAffiliateProducts);
</script>
