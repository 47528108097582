<script setup>
import { defineProps } from "vue"
const props = defineProps({
  sales: {
    type: Object,
    required: true,
  },
});
</script>
<template>
  <section class="overlay">
    <div class="c-modal w-30">
      <div class="d-flex justify-content-between">
        <h5 v-text="'Detalles'" />

        <button class="btn btn-closed" @click="$emit('close')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
            width="20"
            height="20"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <table class="table">
        <tbody>
           <tr>
            <td><strong>Total de compra</strong></td>
            <td class="text-end">USD$ {{ props.sales.total }}</td>
          </tr>
          <tr>
            <td><strong>Total afiliado</strong></td>
            <td class="text-end">USD$ {{ props.sales.total_affiliate }}</td>
          </tr>

          <tr>
            <td><strong>Comisión skill-pay</strong></td>
            <td class="text-end">USD$ {{ props.sales.total_us }}</td>
          </tr>
          <tr>
            <td><strong>Total producer</strong></td>
            <td class="text-end">USD$ {{ props.sales.total_producer }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<style scoped>
.w-30 {
  width: 30%;
}
</style>
