<script setup>
import DataTable from "@/components/common/DataTable.vue"
import { useOffersStore } from "@/stores/Offers"
import { computed, onMounted } from "vue"

const offersStore = useOffersStore();


const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Producto",
  },
  {
    width: "w-4/12",
    title: "Precio",
  },
  {
    width: "w-4/12",
    title: "",
  },

]);

onMounted(async () => {

  await offersStore.getOffers();
});

// const changePage = async page => {
//   if (page < 1 || page > salesStore.pageCount) return
//   currentPage.value = page
//   await salesStore.getSales(
//     product.value,
//     dateFrom.value,
//     dateTo.value,
//     page,
//     state.value
//   )
// }




// watch(salesStore.page, async (page, previous) => {
//   if (previous !== 0 && page !== 0) {
//     await salesStore.getSales(
//       product.value,
//       dateFrom.value,
//       dateTo.value,
//       page,
//       state.value
//     );
//   }
// });

</script>
<template>
  <section>
 

    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.OFFERS") }}</h2>
      </div>
  
    </section>
    <DataTable :noHaveData="data?.length === 0" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in offersStore?.data" :key="index">
          <td class="p-3" v-text="item.product.title || '-'" />
          <td class="p-3" v-text="item.product.price || '-'" />
          <td class="p-3">
            <button class="btn bg-green text-white">
              Ir
            </button>
          </td>
     
        </tr>
      </template>
    </DataTable>
    <!-- <nav
      >
        <ul class="index-nav">
          <li>
            <button
              href="#"
              :class="{
                'cursor-not-allowed opacity-50':
                  salesStore.page === 1,
              }"
              :disabled="salesStore.page === 1"
              @click.prevent="changePage(currentPage - 1)"
            >
              Anterior
            </button>
          </li>
          <li v-for="page in salesStore.pageCount" :key="page">
            <button
              :disabled="salesStore.page === page"
              :class="{ 'font-bold': currentPage === page }"
              @click.prevent="changePage(page)"
            >
              {{ page }}
            </button>
          </li>
          <button
            :class="{
              'cursor-not-allowed opacity-50':
                currentPage === salesStore.pageCount,
            }"
            @click.prevent="changePage(currentPage + 1)"
            >Siguiente</button
          >
          <li>
          </li>
        </ul>
      </nav> -->
  </section>
</template>
