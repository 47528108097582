<script setup>
import { defineEmits, defineProps } from "vue"

const emit = defineEmits(['change'])
const props = defineProps({
  for: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <label class="style-check font-medium text-tertiary" :for="props.for">
    <input :id="props.for" type="checkbox" :checked="props.checked" :disabled="props.isDisabled" @change="emit('change')" />
    <span class="checkmark checkmark__blue" />
    <span v-text="props.text" />
  </label>
</template>
