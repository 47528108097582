import { defineStore } from 'pinia';

export const useCoreStore = defineStore('core', {
  state: () => ({
    subdomain: null,
  }),
  actions: {
    setSubdomain(value) {
      this.subdomain = value;
    },
  },
});