<template>
  <AppHeader />
  <div class="">
    <div class="row">
      <AppSidebar class="col-md-2 col-lg-2" />
      <main class="col-12 layout-main">
        <div class="content-dash">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
<template v-if="
        (subdomain === 'skillper' && producerStore.verifiedUser === 'error' || subdomain === 'affiliate' && affiliateStore.verifiedUser === 'error')
      ">
    <section class="overlay">
      <section class="modal-producer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class=""
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
          />
        </svg>

        <p
          class="title"
          v-text="
            subdomain === 'skillper'
              ? 'Debe registarse como productor'
              : 'Debe registrarse como afiliado'
          "
        />
        <a
          class="btn btn-dark"
          v-text="'Registrarse'"
          :href="
            subdomain === 'skillper' ? `${URL}/producer` : `${URL}/affiliate`
          "
          target="_blank"
        />
      </section>
    </section>
  </template>
</template>

<script setup>
import AppHeader from "@/components/Header.vue"
import AppSidebar from "@/components/SideBar.vue"
import { useAffiliateStore } from "@/stores/Affiliate"
import { useCoreStore } from "@/stores/core"
import useProducerStore from "@/stores/Producer"
import { useAuth0 } from "@auth0/auth0-vue"
import { computed, onMounted } from "vue"

const { getAccessTokenSilently } = useAuth0();
const coreStore = useCoreStore();
const subdomain = coreStore.subdomain;
const producerStore = useProducerStore();
const affiliateStore = useAffiliateStore();
const { user, isAuthenticated } = useAuth0();
const URL = process.env.VUE_APP_MARKET_URL;

const isAuthenticatedValue = computed(() => isAuthenticated.value);
const userValue = computed(() => user.value);

onMounted(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    });
    localStorage.setItem("token", token);

  if (isAuthenticatedValue.value) {
    await performAuthenticationActions(userValue.value);
  }
});

async function performAuthenticationActions(userData) {
  const userId = userData?.sub;

  if (subdomain === "skillper") {
    await producerStore.verifyProducer(userId);
  }
  if (subdomain === "affiliate") {
    await affiliateStore.verifyAffiliate(userId);
  }
}

// watch([isAuthenticated, user], async ([isAuth, userData]) => {
//     console.log(isAuthenticated.value, 'watch')

//   if (isAuth && userData && userData.sub && subdomain === "skillper") {
//     await producerStore.verifyProducer(userData.sub);
//   }
//   if (isAuth && userData && userData.sub && subdomain === "affiliate") {
//     await affiliateStore.verifyAffiliate(userData.sub);
//   }
// });
</script>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  z-index: 99999;
  background: #000000a3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-producer {
  background: #fff;
  border-radius: 10px;
  padding: 50px 10px;
  text-align: center;
  width: 30%;
}
.modal-producer .title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 1rem;
}
.modal-producer svg {
  width: 50px;
  height: 50px;
  color: orange;
}
</style>
