<template>
  <div class="sidebar bgprimary-dark" :class="{ 'collapsed': isCollapsed && !isDesktop }">
    <button @click="toggleSidebar" class="toggle-btn" v-if="!isDesktop">
      <i :class="isCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"></i>
    </button>
    <ul class="list-unstyled">
      <li v-if="isProducer">
        <router-link to="/" class="text-decoration-none d-flex align-items-center py-2 mb-1 px-1 gap-2">
          <HomeIcon/>
          <span v-if="!isCollapsed || isDesktop">Dashboard</span>
        </router-link>
      </li>
      <li v-if="isProducer">
        <router-link to="/products" class="text-decoration-none d-flex align-items-center py-2 mb-1 gap-2 px-1">
          <DiskIcon/>
          <span v-if="!isCollapsed || isDesktop">{{ $t('MENU.PRODUCTS') }}</span>
        </router-link>
      </li>
      <li v-if="isProducer">
        <router-link to="/affiliates" class="text-decoration-none d-flexb gap-2 align-items-center py-2 mb-1 px-1">
          <UserIcon/> 
          <span v-if="!isCollapsed || isDesktop">{{ $t('MENU.AFFILIATES') }}</span>
        </router-link>
      </li>
      <li v-if="!isProducer">
        <router-link to="/affiliate-sales" class="text-decoration-none gap-2 d-flex align-items-center py-2 mb-1 px-1">
          <VentasIcon/>
          <span v-if="!isCollapsed || isDesktop">Ventas de afiliados</span>
        </router-link>
      </li>
      <li v-if="!isProducer">
        <router-link to="/affiliate-products" class="text-decoration-none gap-2 d-flex align-items-center py-2 mb-1 px-1">
          <DiskIcon/>
          <span v-if="!isCollapsed || isDesktop">Productos afiliados</span>
        </router-link>
      </li>
      <li v-if="isProducer">
        <router-link to="/sales" class="text-decoration-none gap-2 d-flex align-items-center py-2 mb-1 px-1">
          <VentasIcon/>
          <span v-if="!isCollapsed || isDesktop">{{ $t('MENU.SALES') }}</span>
        </router-link>
      </li>
      <li>
        <router-link to="/payouts" class="text-decoration-none d-flex align-items-center py-2 mb-1 px-1 gap-2">
          <i class="fas fa-dollar-sign"></i>
          <span v-if="!isCollapsed || isDesktop">Pagos</span>
        </router-link>
      </li>
      <li>
        <router-link to="/offers" class="text-decoration-none d-flex align-items-center py-2 mb-1 px-1 gap-2">
          <i class="fas fa-percentage"></i>
          <span v-if="!isCollapsed || isDesktop">Ofertas</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profile" class="text-decoration-none d-flex align-items-center py-2 mb-1 px-1 gap-2">
          <i class="fas fa-user"></i>
          <span v-if="!isCollapsed || isDesktop">Profile</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import DiskIcon from '@/assets/icons/diskIcon.vue'
import HomeIcon from '@/assets/icons/homeIcon.vue'
import UserIcon from '@/assets/icons/userIcon.vue'
import VentasIcon from '@/assets/icons/ventasIcon.vue'
import { onMounted, onUnmounted, ref } from 'vue'



const isCollapsed = ref(true);
const isDesktop = ref(window.innerWidth >= 1024);

const isProducer = process.env.VUE_APP_APP_TYPE==='skillper';

const toggleSidebar = () => {
  if (!isDesktop.value) {
    isCollapsed.value = !isCollapsed.value;
  }
};

const checkScreenSize = () => {
  isDesktop.value = window.innerWidth >= 1024;
  if (isDesktop.value) {
    isCollapsed.value = false;
  }
};

onMounted(() => {
  window.addEventListener('resize', checkScreenSize);
  checkScreenSize();
});

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize);
});
</script>

<style scoped>
.sidebar {
  width: 240px;
  position: fixed;
  top: 80px;
  left: 0;
  height: calc(100% - 56px);
  overflow-y: auto;
  padding-top: 20px;
  background-color: #343a40;
  z-index: 1000;
  border-right: 1px solid #e9ecef;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.sidebar a {
  display: flex;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
}

.sidebar a:hover {
  background-color: #1F2937;
  border-radius: 10px;
}

.sidebar.collapsed a {
  justify-content: center;
}

.router-link-active {
  background-color: #1F2937;
  border-radius: 10px;
}

.toggle-btn {
  align-self: flex-end;
  margin: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .sidebar {
    width: 240px !important;
  }

  .toggle-btn {
    display: none;
  }
}
</style>